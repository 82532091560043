<template>
    <div class="content_container regular_lease">
		<div class="content">
			<div class="banner">
				<h1>할부리스</h1>
			</div>
			<ul class="common_tab">
				<li class="current">
					<router-link to="/product/LeaseNormal">일반 리스</router-link>
				</li>
				<li>
					<router-link to="/product/LeaseFinance">할부 금융</router-link>
				</li>
			</ul>
			<h2 class="title_product">일반 리스</h2>
			<section class="overview">
				<h3>개요</h3>
				<ul>
					<li>
						<div class="content_wrapper">
							<img src="@/assets/images/sub/regular_lease/regular_lease_overview_icon01.svg" alt="Lease Financing" />
							<p>Lease<br />Financing</p>
						</div>
					</li>
					<li>
						<div class="content_wrapper">
							<img src="@/assets/images/sub/regular_lease/regular_lease_overview_icon02.svg" alt="Equipment Rental" />
							<p>Equipment<br />Rental</p>
						</div>
					</li>
					<li>
						<div class="content_wrapper">
							<img src="@/assets/images/sub/regular_lease/regular_lease_overview_icon03.svg" alt="Low First Cost " />
							<p>Low First<br />Cost</p>
						</div>
					</li>
				</ul>
				<div class="textBox">
					<p>고객이 직접 선정한 각종 일반시설 장비(공작기계, 인쇄장비, 의료기 등)를 무궁화캐피탈이 공급자로부터 구입하여 리스기간동안 리스이용자에게 대여해 주고 리스료를 받는 금융상품입니다.
					</p>
				</div>
			</section>
			<section class="structure">
				<h3>운용구조</h3>
				<div class="structure_infogp">
					<p><img src="@/assets/images/sub/regular_lease/regular_lease_structure_infogp.png" alt="무궁화캐피탈 일반리스 운용구조"
					></p>
				</div>	
			</section>
			<section class="process">
				<h3>운용 프로세스</h3>
				<div class="process_infogp">
					<ol>
						<li>
							<div class="rl_process_title">
								<p><img src="@/assets/images/sub/regular_lease/regular_lease_process01.png" alt="01.장비선정" /></p>
							</div>
							<div class="rl_process_contents">
								<p>리스 장비 선정 및 구매조건 확정</p>
							</div>
						</li>
						<li>
							<div class="rl_process_title">
								<p><img src="@/assets/images/sub/regular_lease/regular_lease_process02.png" alt="02.리스신청" /></p>
							</div>
							<div class="rl_process_contents">
								<p>리스 기간, 소요자금 등 제반 리스 조건 협의</p>
							</div>
						</li>
						<li>
							<div class="rl_process_title">
								<p><img src="@/assets/images/sub/regular_lease/regular_lease_process03.png" alt="03.리스승인 및 계약 체결" /></p>
							</div>
							<div class="rl_process_contents">
								<p>거래조건 확정 및 취급 여부심사</p>
							</div>
						</li>
						<li>
							<div class="rl_process_title">
								<p><img src="@/assets/images/sub/regular_lease/regular_lease_process04.png" alt="04.리스 장비 구매 계약" /></p>
							</div>
							<div class="rl_process_contents">
								<p>발주매매 계약<br /><span>해외 발주 : 신용장 개설, 통관, 운송</span></p>
							</div>
						</li>
						<li>
							<div class="rl_process_title">
								<p><img src="@/assets/images/sub/regular_lease/regular_lease_process05.png" alt="05.리스 장비 설치" /></p>
							</div>
							<div class="rl_process_contents">
								<p>리스 이용자의 물건 수령증 발급, 장비 검수</p>
							</div>
						</li>
						<li>
							<div class="rl_process_title">
								<p><img src="@/assets/images/sub/regular_lease/regular_lease_process06.png" alt="06.리스 장비 대금 지급"></p>
							</div>
							<div class="rl_process_contents">
								<p>공급자에게 대금 지급 및 리스 개시</p>
							</div>
						</li>
						<li>
							<div class="rl_process_title">
								<p><img src="@/assets/images/sub/regular_lease/regular_lease_process07.png" alt="07.리스료 납부"></p>
							</div>
							<div class="rl_process_contents">
								<p>리스 이용자가 금융회사에게 납부</p>
							</div>
						</li>	
					</ol>
				</div>	
			</section>

			<Online />
		</div>
	</div>
</template>

<script>
import Online from "@/components/Online.vue"

export default {
    name : "InvestPrivate",
	components: { 
		Online
	},
}
</script>

